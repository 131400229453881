import React from "react";
import {
  Typography,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableBody,
  Stack,
} from "@mui/material";

function CustomizedTable({
  headers, values, align, isDark = false,
}) {
  return (
    <Table>
      {headers && (
        <TableHead>
          <TableRow>
            {headers.map((header, index) => (
              <TableCell align={index !== 0 ? align : "start"}>
                <Typography fontSize={14} color={isDark && "white"}>
                  {header}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
      )}
      <TableBody>
        {values.map((item) => (
          <TableRow sx={{ height: "6.5rem" }}>
            {Object.values(item).map((value, index) => (
              <TableCell align={index !== 0 ? align : "start"}>
                {Array.isArray(value) > 0 ? (
                  <div>
                    {value?.map((name) => (
                      <Stack spacing={1}>
                        <Typography variant="small" color={isDark && "white"}>
                          {name}
                        </Typography>
                      </Stack>
                    ))}
                  </div>
                ) : (
                  <Typography variant={index === Object.values(item).length - 1 ? "h5" : "small"} color={isDark && "white"}>{value}</Typography>
                )}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

export default CustomizedTable;
