import React from "react";
import Highcharts from "highcharts/highstock";
import { Box, Typography } from "@mui/material";
import moment from "moment";
import share from "../services/share";

function HistoricalData() {
  const historicalChartId = "historical-chart";

  React.useEffect(() => {
    share().getHistoricalPrices().then(({ history }) => {
      const tickerPair = "AMS:ALLFG";
      Highcharts.stockChart(historicalChartId, {
        credits: {
          enabled: false,
        },
        rangeSelector: {
          selected: 3,
        },
        xAxis: {
          type: "date",
        },
        tooltip: {
          xDateFormat: "%Y-%m-%d",
        },
        series: [
          {
            name: tickerPair,
            data: history?.historyDt.map(({ dt, lastPx }) => [
              moment(dt, "YYYYMMDD").valueOf(),
              parseFloat(lastPx),
            ]),
            tooltip: {
              valueDecimals: 2,
            },
          },
        ],
      });
    });
  }, []);

  return (
    <Box
      padding={2}
      sx={{
        border: (theme) => `1px solid ${theme.palette.primary.main}`,
        minHeight: "500px",
        borderRadius: "5px",
      }}
    >
      <Typography fontWeight="700">HISTORICAL</Typography>
      <div id={historicalChartId} />
    </Box>
  );
}

export default HistoricalData;
