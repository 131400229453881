import React from "react";
import Highcharts from "highcharts/highstock";
import { Box, Typography } from "@mui/material";
import share from "../services/share";
import { parseDateTime } from "../services/utils";

function IntradayData() {
  const intradayChartId = "intraday-chart";

  React.useEffect(() => {
    share()
      .getIntradayPrices()
      .then(({ intradayPoint }) => {
        const ohlc = intradayPoint?.map(
          ({
            time, beginPx, highPx, lowPx, endPx,
          }) => [
            parseDateTime(time).utc(true).valueOf(),
            parseFloat(beginPx),
            parseFloat(highPx),
            parseFloat(lowPx),
            parseFloat(endPx),
          ],
        );

        const tickerPair = "AMS:ALLFG";

        Highcharts.stockChart(intradayChartId, {
          credits: {
            enabled: false,
          },
          series: [
            {
              name: tickerPair,
              type: "candlestick",
              data: ohlc,
              tooltip: {
                valueDecimals: 2,
              },
            },
          ],
          chart: {
            type: "line",
            zoomType: "x",
            panning: true,
            panKey: "shift",
          },
          rangeSelector: {
            buttons: [
              {
                type: "minutes",
                count: 15,
                text: "15m",
              },
              {
                type: "hour",
                count: 1,
                text: "1h",
              },
              {
                type: "all",
                count: 1,
                text: "All",
              },
            ],
            selected: 2,
            inputEnabled: false,
          },
        });
      });
  }, []);

  return (
    <Box
      padding={2}
      sx={{ border: (theme) => `1px solid ${theme.palette.primary.main}`, minHeight: "500px", borderRadius: "5px" }}
    >
      <Typography fontWeight="700">INTRADAY</Typography>
      <div id={intradayChartId} />
    </Box>
  );
}

export default IntradayData;
